import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import poweredBy from "../assets/images/poweredby-inndox.png";
import { AccountRoutes } from "../routes";
import { selectCompany } from "../store/features/company.slice";
import {
  selectCurrentLogbook,
  selectLogbooks,
} from "../store/features/logbooks.slice";
import { selectPaymentDetails } from "../store/features/subscription.slice";
import { isCurrentUserACompany } from "../store/features/user.slice";
import { useAuth } from "./authentication";

const FooterContainer = styled.footer`
  border-top: 1px solid #d8d8d8;
  background-color: #fff;

  .col {
    height: 55px;
    color: #9b9b9b;
    font-size: 13px;

    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;

      li,
      a {
        margin-left: 4px;
        color: #9b9b9b;
      }
    }
  }
`;

// isSponsored: true -> logbook access is allowed
// sponsoredDaysRemaining > 0 -> access for same days. Show banner
// isSponsored: false -> logbook access is not allowed

export default function Footer() {
  const { session } = useAuth();
  const isLoggedIn = !!session;
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const isCompanyAMasterCompany = useSelector(
    (state) => selectCompany(state)?.isMasterCompany,
  );
  const paymentDetails = useSelector(selectPaymentDetails);
  const hasValidPlan = paymentDetails?.planStatus === "Active";
  const freeTrailDaysRemaining = paymentDetails?.freeTrialDaysRemaining;
  const hasNeverSubscribed = paymentDetails?.planStatus === "None";
  const logbooks = useSelector(selectLogbooks);
  const sponsoredLogbooks = logbooks?.filter((logbook) =>
    logbook?.companies?.some((company) => company?.isSponsored),
  );

  // If owner is fully sponsored i.e. all logbooks are sponsored
  const isSponsoredOwner = sponsoredLogbooks?.length === logbooks?.length;

  const currentLogbook = useSelector(selectCurrentLogbook);
  const companyName = currentLogbook?.companies?.[0]?.name;

  let banner = <noscript />;

  if (!!paymentDetails) {
    banner = (
      <div
        id="subscription-banner"
        style={{
          backgroundColor: "#000",
          position: "sticky",
          bottom: 0,
          zIndex: 1,
        }}
      >
        <SponsoredOrSubscriptionBanner
          companyName={companyName}
          hasValidPlan={hasValidPlan}
          isSponsoredOwner={isSponsoredOwner}
          freeTrailDaysRemaining={freeTrailDaysRemaining}
          isCompanyUser={isCompanyUser && !isCompanyAMasterCompany}
          hasNeverSubscribed={hasNeverSubscribed}
        />
      </div>
    );
  }

  return (
    <>
      {banner}
      <FooterContainer>
        <Container>
          <Row>
            <Col xs={4} className="flex start col">
              <img src={poweredBy} alt="inndox logo" width={96} />
            </Col>

            <Col xs={8} className="flex end col">
              <ul>
                <li>
                  <a
                    href="http://www.inndox.com/resources"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Resources
                  </a>
                </li>
                <li>|</li>
                <li>
                  <Hidden xs>
                    <a
                      href="https://www.inndox.com/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      FAQs
                    </a>
                  </Hidden>

                  <Visible xs>
                    <a
                      href="https://www.inndox.com/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      FAQs
                    </a>
                  </Visible>
                </li>
                <li>|</li>
                <li>
                  <Hidden xs>
                    <a
                      href="https://www.inndox.com/terms-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </Hidden>

                  <Visible xs>
                    <a
                      href="https://www.inndox.com/terms-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms
                    </a>
                  </Visible>
                </li>
                <li>|</li>
                <li>
                  <Hidden xs>
                    <a
                      href="https://www.inndox.com/about-1/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      About Us
                    </a>
                  </Hidden>

                  <Visible xs>
                    <a
                      href="https://www.inndox.com/about-1/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      About
                    </a>
                  </Visible>
                </li>
                <li>|</li>
                <li>
                  <Hidden xs>
                    <a
                      href="https://www.inndox.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </Hidden>

                  <Visible xs>
                    <a
                      href="https://www.inndox.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy
                    </a>
                  </Visible>
                </li>

                {isLoggedIn ? (
                  <>
                    <li>|</li>
                    <li>
                      <Hidden xs>
                        <a
                          href="https://www.inndox.com/enquire-now"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Contact Us
                        </a>
                      </Hidden>

                      <Visible xs>
                        <a
                          href="https://www.inndox.com/enquire-now"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Contact
                        </a>
                      </Visible>
                    </li>
                  </>
                ) : null}
              </ul>
            </Col>
          </Row>
        </Container>
      </FooterContainer>
    </>
  );
}

function SponsoredLogbookBannerOrChildren({
  companyName,
  isCompany,
  children,
}) {
  const { session } = useAuth();

  if (companyName && !isCompany && !!session) {
    return (
      <div
        id="sponsored-banner"
        style={{ backgroundColor: "#000", position: "sticky", bottom: 0 }}
      >
        <Container>
          <Row>
            <Col xs={12} className="flex center" style={{ height: 56 }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: 14,
                  lineHeight: 1.43,
                  marginRight: 8,
                  fontWeight: 600,
                }}
              >
                This logbook is sponsored by {companyName}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return children || <></>;
}

function SponsoredOrSubscriptionBanner({
  companyName,
  hasValidPlan,
  isSponsoredOwner,
  freeTrailDaysRemaining,
  isCompanyUser,
  hasNeverSubscribed,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (isCompanyUser) {
    // only subscription banner
    if (!hasValidPlan) {
      const subscribetext = "Subscribe now to get the most out of Inndox";
      return (
        // <SponsoredLogbookBannerOrChildren
        // 	companyName={companyName}
        // 	isCompany={isCompanyUser}>
        <Container>
          <Row>
            <Col xs={12} className="flex center" style={{ height: 56 }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: 14,
                  lineHeight: 1.43,
                  marginRight: 8,
                  fontWeight: 600,
                }}
              >
                {hasNeverSubscribed
                  ? subscribetext
                  : `Your subscription is not active. ${subscribetext}`}
              </p>

              <button
                className="button button-primary"
                onClick={() =>
                  navigate(
                    "/" +
                      AccountRoutes.basePath.replace("/*", "") +
                      "/" +
                      AccountRoutes.addPaymentDetails,
                  )
                }
              >
                Manage
              </button>
            </Col>
          </Row>
        </Container>
        // </SponsoredLogbookBannerOrChildren>
      );
    }

    return <noscript />;
  }

  // subscription or sponsored banner
  if (pathname.startsWith("/owner/logbooks/")) {
    // sponsored logbook banner
    return (
      <SponsoredLogbookBannerOrChildren
        companyName={companyName}
        isCompany={isCompanyUser}
      />
    );
  }

  // subscription banner
  if (!hasValidPlan) {
    if (isSponsoredOwner) {
      return <noscript />;
    } else {
      // usual banner
      if (freeTrailDaysRemaining > 0) {
        return (
          <Container>
            <Row>
              <Col xs={12} className="flex center" style={{ height: 56 }}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: 14,
                    lineHeight: 1.43,
                    marginRight: 8,
                    fontWeight: 600,
                  }}
                >
                  Your free trial ends in {freeTrailDaysRemaining} days.
                </p>
              </Col>
            </Row>
          </Container>
        );
      }

      return <noscript />;
    }
  }
}
